import React from "react";
import { Copy } from "lucide-react";
const TextWidget = ({ value, onCopy }) => {
    return (
        <div className=" p-1 rounded-lg ">
            <div className="text-zinc-200 text-xs flex items-center">
                <span>{value}</span>
                {onCopy && (
                    <button
                        onClick={onCopy}
                        className="p-1 hover:bg-zinc-700 rounded-md transition-colors ml-2"
                    >
                        <Copy size={14} className="text-zinc-400" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default TextWidget;
