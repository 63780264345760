import { Copy } from "lucide-react";
import React from "react";

const ReadOnlyField = ({ label, value, onCopy }) => (
    <div className="bg-zinc-900/30 p-3 rounded-lg transform scale-90">
        <label className="block text-sm font-medium text-zinc-400 mb-1">{label}</label>
        <div className="text-zinc-100 text-xs flex items-center">
            <span className="flex-grow">{value}</span>
            {onCopy && (
                <button
                    onClick={onCopy}
                    className="p-1 hover:bg-zinc-700 rounded-md transition-colors ml-2"
                    title="Copy to clipboard"
                >
                    <Copy size={14} className="text-zinc-400" />
                </button>
            )}
        </div>
    </div>
);

export default ReadOnlyField;
