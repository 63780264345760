import React, { useEffect } from "react";

const StatusMessage = ({
    message,
    type = "info",
    onDismiss,
    autoDismiss = true,
    mt = "mt-6",
    dismissOnClick = false,
}) => {
    useEffect(() => {
        if (message && autoDismiss) {
            const timer = setTimeout(() => {
                onDismiss();
            }, 3821);

            return () => clearTimeout(timer);
        }
    }, [message, onDismiss, autoDismiss]);

    if (!message) return null;

    const styles = {
        info: "bg-amber-500/10 border-amber-500/20 text-amber-200",
        // error: "bg-rose-500/10 border-rose-500/20 text-rose-200",
        error: "bg-red-500/10 border-red-500/20 text-red-200",
        // success: "bg-emerald-500/10 border-emerald-500/20 text-emerald-200",
        success: "bg-green-500/10 border-green-500/20 text-green-200",
    };

    return (
        <div
            className={`${mt} mb-6 p-4 border rounded-xl text-center flex justify-between items-center ${styles[type]}`}
            onClick={dismissOnClick ? onDismiss : () => {}}
        >
            <span className="flex-grow">{message}</span>
            <button onClick={onDismiss} className="ml-4 hover:opacity-75 transition-opacity">
                ✕
            </button>
        </div>
    );
};

export default StatusMessage;
