import React, { useState, useEffect, useCallback } from "react";
import { Award, Zap, TrendingUp, Clock } from "lucide-react";
import axios from "axios";
import { BlockchainOverlay } from "../Common/LoadingScreen";
import {
    stakingContract,
    loadUserData,
    checkAndSwitchChain,
} from "../../Services/AlchemyTetherInteract";
import instance from "../../Services/AxiosConfig";
import { useAuth } from "../../Services/AuthContext";
import useSound from "../../Services/SoundService";
import { runAlchemy } from "../../Services/AlchemyProvider";
import AchievementsDisplay from "./Achievements";
import { formatNumberWithCommas } from "../../utils/formatters";

import truncateEthAddress from "truncate-eth-address";
import UserSettings from "./UserSettings";
import { useNavigate, useLocation } from "react-router-dom";
import SEO from "../Common/SEO";
import { Crown } from "lucide-react";
import { Flame } from "lucide-react";
import NumberFlow from "@number-flow/react";
import TextWidget from "../Common/TextWidget";
import StatusMessage from "../Common/StatusMessage";

/**
 *
 */
const UserProfileRewards = () => {
    const { user, error, logout } = useAuth();
    const [achievements, setAchievements] = useState([]);
    const [userData, setUserData] = useState("");
    const [walletAddress, setWalletAddress] = useState("");
    const [status, setStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    // Get the active tab from the URL path
    const getActiveTabFromPath = () => {
        const path = location.pathname.split("/").pop();
        if (["settings", "achievements"].includes(path)) {
            return path;
        }
        return "overview";
    };

    // Initialize activeTab based on current URL
    const [activeTab, setActiveTab] = useState(getActiveTabFromPath());

    // Update activeTab when URL changes
    useEffect(() => {
        setActiveTab(getActiveTabFromPath());
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // Rest of your existing state declarations...

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === "overview") {
            navigate("/profile");
        } else {
            navigate(`/profile/${tab}`);
        }
        playTabSound();
    };
    /**
     *
     */
    async function onSignMessage({ accounts }) {
        if (localStorage.getItem("sign") && localStorage.getItem("sign") !== false) {
            return;
        }

        if (localStorage.getItem("sign") != false) {
            return;
        }

        if (localStorage.getItem("sign") !== false) {
            return;
        }
        // get nonce, uri, domain, date, statement
        const nonce = await instance.get("/api/Siwe/nonce");

        const uri = window.location.origin;
        const domain = window.location.host;
        const date = new Date().toISOString();
        const walletOwnershipSignature =
            "Sign in to BidCoin to start bidding in Web3!\nPlease sign this message to confirm you have access to this wallet address. \nNonce: " +
            nonce +
            "\nUri: " +
            uri +
            "\nDomain: " +
            domain +
            "\nDate: " +
            date;
        try {
            const from = accounts[0];
            // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
            // This uses a Node.js-style buffer shim in the browser.
            const msg = `0x${Buffer.from(walletOwnershipSignature, "utf8").toString("hex")}`;
            const sign = await ethereum.request({
                method: "personal_sign",
                params: [msg, from],
            });
            if (sign) {
                localStorage.setItem("signed", true);
            }
            console.log(sign);
        } catch (err) {
            console.error(err);
            personalSign.innerHTML = `Error: ${err.message}`;
        }
    }
    //called only once
    useEffect(() => {
        /**
         *
         */
        const fetchBidCount = async () => {
            setIsLoading(true);
            runAlchemy();
            try {
                const data = await loadUserData();
                if (data) {
                    setUserData(data);
                    // refreshUserData();
                    // addSmartContractListener();

                    /**
                     *
                     */
                    const fetchWallet = async () => {
                        setIsLoading(true);
                        try {
                            // Step 1: Connect wallet
                            const address = localStorage.getItem("userAddress");
                            if (!address) {
                                throw new Error("Failed to connect wallet");
                            }
                            setWalletAddress(address);
                            setStatus(status);

                            // Step 2: Check and switch chain
                            await checkAndSwitchChain();

                            // Step 3: Sign personal message
                            await onSignMessage({ accounts: [address] });
                        } catch (error) {
                            console.error("Error in wallet connection process:", error);
                            setWalletAddress("");
                            setStatus(`Error: ${error.message}`);
                        } finally {
                            setIsLoading(false);
                        }
                    };
                    fetchWallet();
                    addWalletListener();
                    fetchAchievements();
                }
            } catch (error) {
                console.error("Error fetching user profile data:", error);
                throw error;
                // Handle the error case
            } finally {
                setIsLoading(false);
            }
        };

        fetchBidCount();
    }, []);
    /**
     *
     */

    const playTabSound = useSound("/sounds/tab_change.wav");

    const fetchAchievements = useCallback(async () => {
        try {
            const response = await instance.get("/api/achievements");
            // console.log("🚀 ~ fetchAchievements ~ response:", response);
            if (response.data && response.data.data) {
                // Add default values for each achievement
                const processedAchievements = response.data.data.map((achievement) => ({
                    ...achievement,
                    isUnlocked: false,
                    loading: false,
                    progress: 0,
                }));
                setAchievements(processedAchievements);
            } else {
                console.error("No achievements data in response");
            }
        } catch (error) {
            console.error("Error fetching achievements:", error);
        }
    }, []);

    /**
     *
     */
    function addSmartContractListener() {
        stakingContract.events.Staked({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                // const res = data.returnValues;
                // console.log("Staked Event emitted by: " + res[0] + ", amount: " + res[1]);
                initDatRefresh();
                playTabSound();
            }
        });
        stakingContract.events.UnstakeRequested({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                // const res = data.returnValues;
                // console.log(
                //     "UnstakeRequested Event emitted by: " + res[0] + ", requestTime: " + res[1]
                // );
                initDatRefresh();
                playTabSound();
            }
        });
        stakingContract.events.RewardClaimed({}, async (error, data) => {
            if (error) {
                setStatus("😥 " + error.message);
            } else {
                // const res = data.returnValues;
                // console.log("RewardClaimed Event emitted by: " + res[0] + ", amount: " + res[1]);
                initDatRefresh();
            }
        });
    }

    /**
     *
     */
    async function initDatRefresh() {
        const data = await loadUserData();
        if (data) {
            setUserData(data);
        }
    }

    /**
     *
     */
    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWalletAddress(accounts[0]);
                    setStatus("");
                } else {
                    setWalletAddress("");
                    setStatus("🦊 Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    {/* 🦊{" "} */}
                    <a target="_blank" href={"https://metamask.io/download"}>
                        {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                    </a>
                </p>
            );
        }
    }

    if (error) {
        return <div className="text-red-500">Error: {error}</div>;
    }

    const userStats = [
        /**
         * ~ fetchEventCounts ~ eventCounts: 
         * 
         * {
            "Voted": 0,
            "RewardClaimed": 0,
            "AuctionCreated": 0,
            "AuctionRemoved": 0,
            "AuctionInitialized": 3,
            "BidPlaced": 21,
            "BidBurned": 4,
            "AuctionEnded": 3,
            "RefundClaimed": 0,
            "AssetDelivered": 0,
            "OwnerConfirmedDelivery": 0,
            "WinnerConfirmedDelivery": 0
            }
         */
        {
            icon: Zap,
            label: "Total Bids",
            value: Number(userData?.bidCounts) + Number(userData?.burnCounts) || 0,
            colors: {
                icon: "text-amber-500",
                value: "text-amber-500",
            },
        },
        {
            icon: Crown,
            label: "Bid Counts",
            value: userData?.bidCounts || 0,
            colors: {
                icon: "text-green-500",
                value: "text-green-500",
            },
        },
        {
            icon: Flame,
            label: "Burn Counts",
            value: userData?.burnCounts || 0,
            colors: {
                icon: "text-red-500",
                value: "text-red-500",
            },
        },
        {
            icon: TrendingUp,
            label: "Auctions Ended",
            value: userData?.auctionEndedCounts || 0,
            colors: {
                icon: "text-blue-500",
                value: "text-blue-500",
            },
        },
        {
            icon: Zap,
            label: "Total Staked",
            value: userData?.stakedAmount ?? 0,
            suffix: "BID",
            colors: {
                icon: "text-amber-500",
                value: "text-amber-500",
            },
        },
        {
            icon: Award,
            label: "Wins",
            value: userData?.auctionWins ?? 0,
            colors: {
                icon: "text-emerald-500",
                value: "text-emerald-500",
            },
        },
        {
            icon: TrendingUp,
            label: "Staking Rewards",
            value: `${userData?.rewards ?? 0}`,
            suffix: "USDT",
            colors: {
                icon: "text-green-500",
                value: "text-green-500",
            },
        },
        {
            icon: Flame,
            label: "Tokens Burned",
            value: userData?.tokensBurned || 0,
            colors: {
                icon: "text-red-500",
                value: "text-red-500",
            },
        },
        {
            icon: Clock,
            label: "Time Active",
            type: "time",
            value: user?.joinDate
                ? Math.floor((new Date() - new Date(user?.joinDate)) / (1000 * 60 * 60 * 24)) +
                  " days"
                : "0 days",
            colors: {
                icon: "text-blue-500",
                value: "text-blue-500",
            },
        },
    ];

    const handleCopyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        setStatus("Copied to clipboard");
    };
    /** 
 * * Color Options
 *  Option 1: More contrast with existing colors
<div className="min-h-screen bg-gradient-to-br from-zinc-950 via-zinc-900 to-zinc-800/70 mt-16">

// Option 2: Add amber accent (matches your theme)
<div className="min-h-screen bg-gradient-to-br from-zinc-950 via-zinc-900 to-amber-900/30 mt-16">

// Option 3: More complex gradient with multiple stops
<div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-zinc-900 via-zinc-800 to-zinc-950 mt-16">

// Option 4: Diagonal gradient with amber accent
<div className="min-h-screen bg-gradient-to-br from-zinc-950 via-zinc-900/90 to-amber-900/20 mt-16">
 */
    return (
        <>
            <SEO title="Profile - BidCoin" description="Profile - BidCoin" />
            <BlockchainOverlay loading={isLoading} />

            <div className="min-h-screen bg-gradient-to-br from-zinc-950 via-zinc-900 to-amber-900/30 mt-16">
                {" "}
                <main className="max-w-7xl mx-auto px-4 py-16">
                    {/* Profile Header */}
                    <header className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6 mb-8">
                        <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-6">
                            <div className="flex items-center gap-4">
                                <div className="relative">
                                    <div className="w-16 h-16 rounded-full bg-gradient-to-br from-amber-500 to-amber-600 flex items-center justify-center text-2xl font-bold text-zinc-900">
                                        {user?.firstName?.[0]?.toUpperCase() || walletAddress?.[0]}
                                    </div>
                                    <div className="absolute -bottom-1 -right-1 bg-emerald-500 rounded-full p-1">
                                        <Award className="w-4 h-4 text-zinc-900" />
                                    </div>
                                </div>
                                <div>
                                    <h1 className="text-2xl font-medium text-zinc-100">
                                        {user?.firstName || truncateEthAddress(walletAddress)}
                                    </h1>
                                    <TextWidget
                                        value={truncateEthAddress(walletAddress)}
                                        onCopy={() => handleCopyToClipboard(walletAddress)}
                                    />
                                    <p className="text-zinc-400 text-sm">Joined {user?.joinDate}</p>
                                </div>
                            </div>
                        </div>
                    </header>
                    <StatusMessage
                        message={status}
                        onDismiss={() => setStatus(null)}
                        dismissOnClick={true}
                    />
                    <TabNavigation activeTab={activeTab} handleTabChange={handleTabChange} />
                    <TabContent
                        activeTab={activeTab}
                        userStats={userStats}
                        achievements={achievements}
                        walletAddress={walletAddress}
                    />
                    {/* Status Messages */}
                    {error && (
                        <div className="mt-6 p-4 bg-rose-500/10 border border-rose-500/20 rounded-xl text-rose-200 flex justify-between items-center">
                            {error}
                            <button
                                onClick={() => setError(null)}
                                className="ml-2 text-rose-200 hover:text-rose-100"
                            >
                                ✕
                            </button>
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

/**
 *
 */
const TabNavigation = ({ activeTab, handleTabChange }) => (
    <div className="flex mb-6 border-b border-zinc-700/50 overflow-x-auto scrollbar-hide">
        {[
            { id: "overview", label: "Overview", path: "/profile" },
            { id: "achievements", label: "Achievements", path: "/profile/achievements" },
            { id: "settings", label: "Settings", path: "/profile/settings" },
        ].map((tab) => (
            <button
                key={tab.id}
                className={`py-2 px-4 font-medium ${
                    activeTab === tab.id
                        ? "text-amber-500 border-b-2 border-amber-500"
                        : "text-zinc-400 hover:text-white"
                }`}
                onClick={() => handleTabChange(tab.id)}
            >
                {tab.label}
            </button>
        ))}
    </div>
);

/**
 *
 */
const TabContent = ({ activeTab, userStats, achievements }) => {
    return (
        <>
            {activeTab === "settings" && <UserSettings achievements={achievements} />}
            {activeTab === "overview" && <OverviewTab userStats={userStats} />}
            {activeTab === "achievements" && (
                <div className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                    <h2 className="text-xl font-medium text-zinc-100 mb-6">Achievements</h2>
                    {achievements.length > 0 ? (
                        <AchievementsDisplay achievements={achievements} />
                    ) : (
                        <div className="text-center text-zinc-400 py-8">
                            Loading achievements...
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

/**
 *
 */ const OverviewTab = ({ userStats }) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {userStats.map((stat, index) => (
            <div
                key={index}
                className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-5 hover:border-zinc-600/50 transition-all hover:bg-zinc-800/40"
            >
                {/* Card Content Container */}
                <div className="flex flex-col h-full">
                    {/* Header: Icon + Label */}
                    <div className="flex items-center gap-2.5 mb-3">
                        <div className={`${stat.colors.icon} p-2 rounded-lg bg-zinc-800`}>
                            <stat.icon className="w-4 h-4" />
                        </div>
                        <span className="text-sm font-medium text-zinc-400">{stat.label}</span>
                    </div>

                    {/* Value Display */}
                    <div className={`${stat.colors.value} font-medium text-right`}>
                        {stat?.type === "time" ? (
                            <span className="text-xl">{stat.value}</span>
                        ) : (
                            <div className="flex items-baseline justify-end gap-1">
                                <NumberFlow value={stat.value} className="text-xl" />
                                {stat?.suffix && (
                                    <span className="text-sm opacity-75">{stat.suffix}</span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ))}
    </div>
);

/**
 *
 */
// const ActivityTab = ({ walletAddress }) => {
//     const [activityHistory, setActivityHistory] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [page, setPage] = useState(1);

//     useEffect(() => {
//         // console.log(walletAddress);
//         /**
//          *
//          */
//         const fetchActivityHistory = async () => {
//             if (!walletAddress) {
//                 return;
//             }

//             const ARBISCAN_API_KEY = process.env.REACT_APP_ARBISCAN_API_KEY; // Store this in your .env file
//             const ARBISCAN_API_URL = "https://api.arbiscan.io/api";

//             try {
//                 // console.log(
//                 //   "🚀 ~ fetchActivityHistory ~ ARBISCAN_API_URL:",
//                 //   ARBISCAN_API_URL
//                 // );
//                 // console.log(
//                 //   "🚀 ~ fetchActivityHistory ~ ARBISCAN_API_KEY:",
//                 //   ARBISCAN_API_KEY
//                 // );
//                 // console.log("🚀 ~ fetchActivityHistory ~ Starting Try Catch:");

//                 const response = await axios.get(ARBISCAN_API_URL, {
//                     headers: {
//                         Origin: "https://bidcoin.live", // Replace with your actual origin in production
//                     },
//                     params: {
//                         action: "txlist",
//                         address: walletAddress,
//                         apikey: ARBISCAN_API_KEY,
//                         endblock: "latest",
//                         module: "account",
//                         offset: 10,
//                         page: page,
//                         sort: "desc",
//                         startblock: 0,
//                     },
//                 });
//                 // console.log("🚀 ~ fetchActivityHistory ~ response:" + response);

//                 if (response.data.status === "1") {
//                     const transactions = response.data.result;
//                     const formattedActivity = transactions.map((tx) => ({
//                         amount: `${parseFloat(tx.value) / 1e18} ETH`,
//                         hash: tx.hash,
//                         item: tx.to,
//                         // Convert from Wei to ETH
//                         time: new Date(tx.timeStamp * 1000).toLocaleString(),

//                         type:
//                             tx.to.toLowerCase() === walletAddress.toLowerCase()
//                                 ? "receive"
//                                 : "send",
//                     }));

//                     setActivityHistory((prevHistory) => [...prevHistory, ...formattedActivity]);
//                 } else {
//                     console.error("Error fetching transactions:", response.data.message);
//                 }
//             } catch (error) {
//                 console.error("Error fetching activity history:", error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchActivityHistory();
//     }, [walletAddress, page]);

//     /**
//      *
//      */
//     const loadMore = () => {
//         setPage((prevPage) => prevPage + 1);
//     };

//     return (
//         <>
//             <BlockchainOverlay loading={loading} />
//             <div className="space-y-4">
//                 {activityHistory.map((activity, index) => (
//                     <div
//                         key={index}
//                         className="bg-gray-800 rounded-lg p-4 flex justify-between items-center"
//                     >
//                         <div>
//                             <p className="font-medium">{activity.item}</p>
//                             <p className="text-sm text-gray-400">{activity.time}</p>
//                         </div>
//                         <div className="text-right">
//                             <p
//                                 className={`font-bold ${
//                                     activity.type === "receive"
//                                         ? "text-green-500"
//                                         : "text-yellow-500"
//                                 }`}
//                             >
//                                 {activity.amount}
//                             </p>
//                             <p className="text-sm text-gray-400">
//                                 {activity.type.charAt(0).toUpperCase() + activity.type.slice(1)}
//                             </p>
//                         </div>
//                     </div>
//                 ))}
//                 {activityHistory.length > 0 && (
//                     <button
//                         onClick={loadMore}
//                         className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
//                     >
//                         Load More
//                     </button>
//                 )}
//             </div>
//         </>
//     );
// };

export default UserProfileRewards;
