import React, { useEffect } from "react";
import {
    DollarSign,
    Wallet,
    Clock,
    ArrowRight,
    Shield,
    Gift,
    Timer,
    AlertCircle,
    CheckCircle2,
    ArrowUpCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const HowItWorksScreen = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    const steps = [
        {
            color: "bg-blue-500",
            description:
                "Connect your Web3 wallet and claim 2 BID tokens from our airdrop for first 100,000 users.",
            icon: Wallet,
            title: "Connect & Claim",
        },
        {
            color: "bg-green-500",
            description:
                "Browse live auctions for BTC, ETH, USDT and more. Each auction starts at $0.00.",
            icon: DollarSign,
            title: "Explore Auctions",
        },
        {
            color: "bg-amber-500",
            description:
                "Place $50 USDT bids to increase price by $0.01 and earn BID token rewards.",
            icon: ArrowUpCircle,
            title: "Bid & Earn",
        },
        {
            color: "bg-purple-500",
            description:
                "Enter FOMO Mode once reserve price is hit. 1-minute timer starts with each new bid.",
            icon: Timer,
            title: "FOMO Mode",
        },
    ];

    const benefits = [
        {
            icon: Shield,
            title: "Transparent Process",
            description: "All bids and transactions are verified on-chain through smart contracts",
        },
        {
            icon: Gift,
            title: "Token Rewards",
            description: "Earn 2X BID tokens per bid before reserve, 1X after reserve price",
        },
        {
            icon: DollarSign,
            title: "Competitive Pricing",
            description: "Purchase valuable crypto assets at community-driven prices",
        },
        {
            icon: CheckCircle2,
            title: "Secure & Trustless",
            description: "Smart contracts ensure safe asset delivery and bid refunds",
        },
    ];

    return (
        <div className="min-h-screen  text-zinc-100">
            <main className="container mx-auto px-4 py-8 pt-24">
                <h1 className="text-4xl font-bold mb-6 text-center bg-gradient-to-r from-amber-500 to-amber-300 bg-clip-text text-transparent">
                    How BidCoin Auctions Work
                </h1>

                <div className="bg-zinc-800/50 rounded-2xl p-6 mb-12 text-center backdrop-blur-sm border border-zinc-700/50">
                    <h2 className="text-2xl font-bold mb-2">Buy Crypto at Fair Prices</h2>
                    <p className="text-lg text-zinc-400">
                        Participate in transparent penny auctions to purchase crypto assets and earn{" "}
                        <span className="font-bold text-amber-500">BID tokens</span>
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className="bg-zinc-800/50 rounded-xl p-6 backdrop-blur-sm border border-zinc-700/50 hover:border-amber-500/50 transition-all duration-300"
                        >
                            <div className="bg-amber-500 rounded-full p-3 inline-block mb-4">
                                <step.icon size={24} className="text-zinc-900" />
                            </div>
                            <h3 className="text-xl font-bold mb-2 text-zinc-100">{step.title}</h3>
                            <p className="text-zinc-400">{step.description}</p>
                        </div>
                    ))}
                </div>

                <div className="bg-zinc-800/50 rounded-xl p-8 backdrop-blur-sm border border-zinc-700/50 mb-12">
                    <h3 className="text-2xl font-bold mb-6 text-zinc-100">Auction Rules</h3>
                    <div className="space-y-4">
                        <div className="flex items-start space-x-4">
                            <DollarSign className="text-amber-500 mt-1 flex-shrink-0" />
                            <div>
                                <h4 className="font-semibold">Bid Costs</h4>
                                <p className="text-zinc-400">
                                    Each bid costs 50 USDT and raises the displayed price by $0.01
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-4">
                            <Clock className="text-amber-500 mt-1 flex-shrink-0" />
                            <div>
                                <h4 className="font-semibold">FOMO Mode Timer</h4>
                                <p className="text-zinc-400">
                                    1-minute countdown that resets with each new bid. Last bidder
                                    wins when timer expires
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start space-x-4">
                            <AlertCircle className="text-amber-500 mt-1 flex-shrink-0" />
                            <div>
                                <h4 className="font-semibold">Refund Policy</h4>
                                <p className="text-zinc-400">
                                    Full bid refunds if reserve price isn't reached within 30 days.
                                    Keep earned BID tokens
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    {benefits.map((benefit, index) => (
                        <div
                            key={index}
                            className="bg-zinc-800/50 rounded-xl p-6 backdrop-blur-sm border border-zinc-700/50"
                        >
                            <benefit.icon size={24} className="text-amber-500 mb-4" />
                            <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                            <p className="text-zinc-400 text-sm">{benefit.description}</p>
                        </div>
                    ))}
                </div>

                <div className="text-center">
                    <button
                        onClick={() => navigate("/auctions")}
                        className="bg-amber-500 text-zinc-900 py-3 px-8 rounded-xl hover:bg-amber-600 transition-all duration-200 text-lg font-bold inline-flex items-center"
                    >
                        Start Bidding Now <ArrowRight size={20} className="ml-2" />
                    </button>
                </div>
            </main>
        </div>
    );
};

export default HowItWorksScreen;
