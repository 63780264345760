import React from "react";
import { Network, Alchemy } from "alchemy-sdk";
import { useAppKitAccount, useAppKitProvider } from "@reown/appkit/react";
import { isAddress } from "ethers";

const settings = {
    apiKey: "z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg",
    network: Network.ARB_MAINNET,
};

export const alchemyN1nja = new Alchemy(settings);

// Create a store to hold the current wallet state
let currentProvider = null;
let currentAccount = null;

// Function to update the current provider
export const setCurrentProvider = (provider) => {
    currentProvider = provider;
};

// Function to update the current account
export const setCurrentAccount = (account) => {
    currentAccount = account;
};

export const getAppKitProvider = async () => {
    return currentProvider;
};

export const getAppKitAccount = async () => {
    // console.log("🚀 ~ getAppKitAccount ~ currentAccount:" + currentAccount);
    return currentAccount;
};

// Create a React hook component to handle the wallet connection
export const WalletStateManager = ({ children }) => {
    const { provider } = useAppKitProvider();
    const { address, isConnected } = useAppKitAccount();

    React.useEffect(() => {
        // console.log("🚀 ~ React.useEffect ~ provider:", provider);
        setCurrentProvider(provider);
        // if (!provider) {
        //     setCurrentProvider(window.ethereum);
        // }
        // console.log("🚀 ~ React.useEffect ~ address:", address);
        // console.log("🚀 ~ React.useEffect ~ isAddress(address):", isAddress(address));
        if (isAddress(address)) {
            localStorage.setItem("onboardingComplete", "true");
        }
        setCurrentAccount(address);
        localStorage.setItem("userAddress", address);
    }, [provider, address, isConnected]);

    return children;
};

/**
 *
 */
export async function runAlchemy() {
    // Access standard Ethers.js JSON-RPC node request
    alchemyN1nja.core.getBlockNumber().then(console.log);

    // Access Alchemy Enhanced API requests
    alchemyN1nja.core
        .getTokenBalances("0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9")
        .then(console.log);

    // Access the Alchemy NFT API
    // alchemyN1nja.nft.getNftsForOwner("vitalik.eth").then(console.log);

    // Access WebSockets and Alchemy-specific WS methods
    // alchemyN1nja.ws.on(
    //   {
    //     method: "alchemy_pendingTransactions",
    //   },
    //   (res) => console.log(res)
    // );
    const ethersProvider = await alchemyN1nja.config.getProvider();
    console.log(ethersProvider);
}
