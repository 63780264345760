import React, { useState, useEffect } from "react";
import {
    LogOut,
    Save,
    User,
    Shield,
    Award,
    MapPin,
    Phone,
    Globe,
    Bell,
    Github,
    Twitter,
    LinkedIn,
    Instagram,
    Moon,
    Sun,
    Upload,
} from "lucide-react";
import CookieConsentManager from "../Cookies/CookieConsentManager";
import instance from "../../Services/AxiosConfig";
import { BlockchainOverlay } from "../Common/LoadingScreen";
import { useAuth } from "../../Services/AuthContext";
import { Linkedin } from "lucide-react";
import { GithubIcon } from "lucide-react";
import { TwitterIcon } from "lucide-react";
import { InstagramIcon } from "lucide-react";
import { ChevronLeft } from "lucide-react";
import { ChevronRight } from "lucide-react";
import { useDisconnect } from "@reown/appkit/react";
import { XIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../Onboarding/OnboardingFlow";
import SEO from "../Common/SEO";
import truncateEthAddress from "truncate-eth-address";
import { Copy } from "lucide-react";
import ReadOnlyField from "../Common/ReadOnlyField";
import StatusMessage from "../Common/StatusMessage";

const UserSettings = () => {
    const { startOnboarding } = useOnboarding();
    const navigate = useNavigate();
    const { user, logout, updateUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        birthDate: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
        country: "",
        hideWalletAddress: false,
        selectedBadge: "",
        bio: "",
        profileImageUrl: "",
        socialLinks: {
            twitter: "",
            github: "",
            linkedin: "",
            instagram: "",
        },
        language: "en",
        theme: "dark",
        notificationPreferences: {
            emailNotifications: true,
            pushNotifications: true,
            bidNotifications: true,
            auctionNotifications: true,
            stakingNotifications: true,
        },
    });
    const [message, setMessage] = useState({ type: "", content: "" });
    const [activeSection, setActiveSection] = useState("account");
    const [isMobileView, setIsMobileView] = useState(false);
    const achievements = JSON.parse(localStorage.getItem("userAchievements")) || [];
    const [badgeUrl, setBadgeUrl] = useState(null);
    const { disconnect } = useDisconnect();

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        if (isMobileView) {
            setActiveSection(null);
        }
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        const fetchLatestUserData = async () => {
            if (!user?.id) return;

            setIsLoading(true);
            try {
                const response = await instance.get(`/api/users/${user.id}`);
                if (response.data.success) {
                    const userData = response.data.data;
                    updateUser(userData);
                    setFormData({
                        firstName: userData.firstName || "",
                        lastName: userData.lastName || "",
                        birthDate: userData.dateOfBirth ? userData.dateOfBirth.split("T")[0] : "",
                        address: userData.address || "",
                        city: userData.city || "",
                        state: userData.state || "",
                        postalCode: userData.postalCode || "",
                        phone: userData.phoneNumber || "",
                        country: userData.country || "",
                        hideWalletAddress: userData.hideWalletAddress || false,
                        selectedBadge: userData.selectedBadge || "",
                        bio: userData.bio || "",
                        profileImageUrl: userData.profileImageUrl || "",
                        socialLinks: userData.socialLinks || {
                            twitter: "",
                            github: "",
                            linkedin: "",
                            instagram: "",
                        },
                        language: userData.language || "en",
                        theme: userData.theme || "dark",
                        notificationPreferences: {
                            emailNotifications:
                                userData.notificationPreferences?.emailNotifications ?? true,
                            pushNotifications:
                                userData.notificationPreferences?.pushNotifications ?? true,
                            bidNotifications:
                                userData.notificationPreferences?.bidNotifications ?? true,
                            auctionNotifications:
                                userData.notificationPreferences?.auctionNotifications ?? true,
                            stakingNotifications:
                                userData.notificationPreferences?.stakingNotifications ?? true,
                        },
                    });
                }
            } catch (error) {
                setMessage({
                    type: "error",
                    content: "Failed to fetch latest user data",
                });
                console.error("Error fetching user data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLatestUserData();
    }, [user?.id, updateUser]);

    useEffect(() => {
        const fetchBadgeUrl = async () => {
            if (!formData.selectedBadge) {
                setBadgeUrl(null);
                return;
            }

            try {
                const response = await instance.get(
                    `/api/utils/get-badge-url?badgeId=${formData.selectedBadge}`
                );
                if (response.data) {
                    setBadgeUrl(response.data);
                }
            } catch (error) {
                console.error("Error fetching badge URL:", error);
                setBadgeUrl(null);
            }
        };

        fetchBadgeUrl();
    }, [formData.selectedBadge]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name.includes(".")) {
            const [parent, child] = name.split(".");
            setFormData((prev) => {
                const newState = {
                    ...prev,
                    [parent]: {
                        ...prev[parent],
                        [child]: type === "checkbox" ? checked : value,
                    },
                };
                // console.log("Updated State:", newState);
                return newState;
            });
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Helper function to check if a string is empty
            const isEmpty = (str) => !str || str.trim() === "";

            // Helper function to check if an object has any non-empty values
            const isObjectEmpty = (obj) =>
                Object.values(obj).every((value) =>
                    typeof value === "string" ? isEmpty(value) : !value
                );

            const updateDto = {
                firstName: isEmpty(formData.firstName) ? null : formData.firstName,
                lastName: isEmpty(formData.lastName) ? null : formData.lastName,
                birthDate: isEmpty(formData.birthDate) ? null : formData.birthDate,
                address: isEmpty(formData.address) ? null : formData.address,
                city: isEmpty(formData.city) ? null : formData.city,
                state: isEmpty(formData.state) ? null : formData.state,
                postalCode: isEmpty(formData.postalCode) ? null : formData.postalCode,
                phoneNumber: isEmpty(formData.phone) ? null : formData.phone,
                country: isEmpty(formData.country) ? null : formData.country,
                hideWalletAddress: formData.hideWalletAddress,
                selectedBadge: isEmpty(formData.selectedBadge) ? null : formData.selectedBadge,
                bio: isEmpty(formData.bio) ? null : formData.bio,
                profileImageUrl: isEmpty(formData.profileImageUrl)
                    ? null
                    : formData.profileImageUrl,
                socialLinks: isObjectEmpty(formData.socialLinks)
                    ? null
                    : {
                          twitter: isEmpty(formData.socialLinks.twitter)
                              ? null
                              : formData.socialLinks.twitter,
                          github: isEmpty(formData.socialLinks.github)
                              ? null
                              : formData.socialLinks.github,
                          linkedin: isEmpty(formData.socialLinks.linkedin)
                              ? null
                              : formData.socialLinks.linkedin,
                          instagram: isEmpty(formData.socialLinks.instagram)
                              ? null
                              : formData.socialLinks.instagram,
                      },
                language: isEmpty(formData.language) ? null : formData.language,
                theme: isEmpty(formData.theme) ? null : formData.theme,
                notificationPreferences: isObjectEmpty(formData.notificationPreferences)
                    ? null
                    : {
                          emailNotifications:
                              formData.notificationPreferences.emailNotifications ?? null,
                          pushNotifications:
                              formData.notificationPreferences.pushNotifications ?? null,
                          bidNotifications:
                              formData.notificationPreferences.bidNotifications ?? null,
                          auctionNotifications:
                              formData.notificationPreferences.auctionNotifications ?? null,
                          stakingNotifications:
                              formData.notificationPreferences.stakingNotifications ?? null,
                      },
            };

            // Remove any undefined values
            Object.keys(updateDto).forEach(
                (key) => updateDto[key] === undefined && delete updateDto[key]
            );

            const response = await instance.put(`/api/users/${user.id}`, updateDto);

            if (response.data.success) {
                setMessage({ type: "success", content: "Settings updated successfully!" });
                updateUser(response.data.data);
            }
        } catch (error) {
            console.error("Update error:", error.response?.data);
            setMessage({
                type: "error",
                content: error.response?.data?.title || "Error updating settings",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = async () => {
        disconnect();
        await logout();
        navigate("/");
        startOnboarding();
        window.location.reload();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        // console.log(file);
    };

    const unlockedAchievements = achievements.filter((a) => a.isUnlocked);
    const handleCopyToClipboard = (value) => {
        if (value) {
            navigator.clipboard.writeText(value);
            // Optionally add some visual feedback
            setMessage({ type: "success", content: "Copied to clipboard!" });
            setTimeout(() => setMessage({ type: "", content: "" }), 2000);
        }
    };
    // Define sections configuration
    const sections = {
        account: {
            icon: <User size={20} />,
            title: "Account",
            component: (
                <section className="space-y-4">
                    {/* Account Information Section */}
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            {isMobileView && (
                                <button
                                    onClick={() => setActiveSection(null)}
                                    className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                            )}
                            <User size={20} />
                            Account Information
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <ReadOnlyField
                                label="Username"
                                value={user?.username}
                                onCopy={handleCopyToClipboard}
                            />
                            <ReadOnlyField
                                label="Email"
                                value={user?.email}
                                onCopy={handleCopyToClipboard}
                            />
                            <ReadOnlyField
                                label="Wallet Address"
                                value={truncateEthAddress(user?.walletAddress)}
                                onCopy={handleCopyToClipboard}
                            />
                            <ReadOnlyField
                                label="Join Date"
                                value={new Date(user?.joinDate).toLocaleDateString()}
                            />
                            <div className="flex gap-4 pl-6">
                                <ReadOnlyField
                                    label="Email Verified"
                                    value={user?.isEmailVerified ? "Yes" : "No"}
                                />
                                <ReadOnlyField
                                    label="Phone Verified"
                                    value={user?.isPhoneVerified ? "Yes" : "No"}
                                />
                            </div>
                        </div>
                    </section>
                </section>
            ),
        },
        profile: {
            icon: <User size={20} />,
            title: "Profile",
            component: (
                <section className="space-y-4">
                    {/* Profile Image Section */}
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            {isMobileView && (
                                <button
                                    onClick={() => setActiveSection(null)}
                                    className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                            )}
                            <User size={20} />
                            Profile Image & Bio
                        </h2>
                        <div className="flex flex-col md:flex-row gap-6">
                            <div className="flex-shrink-0">
                                <div className="relative w-32 h-32">
                                    {formData.selectedBadge && (
                                        <div className="absolute top-0 right-0 bg-white p-3 rounded-full cursor-pointer hover:bg-amber-600 transition-colors">
                                            <img
                                                src={`${process.env.REACT_APP_API_BASE_URL}/images/${badgeUrl}`}
                                                alt="Selected Badge"
                                                className="w-8 h-8"
                                            />
                                        </div>
                                    )}
                                    <img
                                        src={
                                            formData.profileImageUrl ||
                                            "https://via.placeholder.com/150"
                                        }
                                        alt="Profile"
                                        className="w-full h-full rounded-full object-cover border-2 border-zinc-700"
                                    />
                                    <label className="absolute bottom-0 right-0 bg-amber-500 p-2 rounded-full cursor-pointer hover:bg-amber-600 transition-colors">
                                        <Upload size={16} className="text-zinc-900" />
                                        <input
                                            type="file"
                                            className="hidden"
                                            onChange={handleImageUpload}
                                            accept="image/*"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="flex-grow">
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Bio
                                </label>
                                <textarea
                                    name="bio"
                                    value={formData.bio}
                                    onChange={handleInputChange}
                                    placeholder="Tell us about yourself..."
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500 min-h-[120px]"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-zinc-400 mb-1">
                                Select Badge
                            </label>
                            <select
                                name="selectedBadge"
                                value={formData.selectedBadge}
                                onChange={handleInputChange}
                                className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                            >
                                <option value="">Select a badge</option>
                                {unlockedAchievements.map((achievement) => (
                                    <option key={achievement.id} value={achievement.id}>
                                        {achievement.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </section>

                    {/* Personal Information Section */}
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            <User size={20} />
                            Personal Information
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    First Name *
                                    <span className="block text-xs text-zinc-500 mt-0.5">
                                        Also used as user display name on leader boards when `Hide
                                        Wallet Address` is activated ✅
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    required
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Birth Date
                                </label>
                                <input
                                    type="date"
                                    name="birthDate"
                                    value={formData.birthDate}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Phone
                                </label>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                        </div>
                    </section>
                </section>
            ),
        },
        privacy: {
            icon: <Shield size={20} />,
            title: "Privacy",
            component: (
                <section className="space-y-4">
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            {isMobileView && (
                                <button
                                    onClick={() => setActiveSection(null)}
                                    className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                            )}
                            <Shield size={20} />
                            Privacy Settings
                        </h2>
                        <div className="space-y-6">
                            <div className="flex items-center justify-between">
                                <div>
                                    <p className="font-medium text-zinc-100">Hide Wallet Address</p>
                                    <p className="text-sm text-zinc-400">
                                        Hide your wallet address from other users
                                    </p>
                                </div>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        name="hideWalletAddress"
                                        checked={formData.hideWalletAddress}
                                        onChange={handleInputChange}
                                        className="sr-only peer"
                                    />
                                    <div className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-500"></div>
                                </label>
                            </div>

                            {/* Cookie Consent Management */}
                            <div className="pt-4 border-t border-zinc-700/50">
                                <h3 className="text-lg font-medium text-zinc-100 mb-3">
                                    Cookie Preferences
                                </h3>
                                <CookieConsentManager />
                            </div>
                        </div>
                    </section>
                </section>
            ),
        },
        address: {
            icon: <MapPin size={20} />,
            title: "Address",
            component: (
                <section className="space-y-4">
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            {isMobileView && (
                                <button
                                    onClick={() => setActiveSection(null)}
                                    className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                            )}
                            <MapPin size={20} />
                            Address Information
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="md:col-span-2">
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Street Address
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    City
                                </label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    State/Province
                                </label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Postal Code
                                </label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={formData.postalCode}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-zinc-400 mb-1">
                                    Country
                                </label>
                                <input
                                    type="text"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleInputChange}
                                    className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                />
                            </div>
                        </div>
                        {/* ... existing address section ... */}
                    </section>
                </section>
            ),
        },
        social: {
            icon: <Globe size={20} />,
            title: "Social",
            component: (
                <section className="space-y-4">
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            {isMobileView && (
                                <button
                                    onClick={() => setActiveSection(null)}
                                    className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                                >
                                    <ChevronLeft size={20} />
                                </button>
                            )}
                            <Globe size={20} />
                            Social Links
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {Object.entries({
                                "x ( Twitter )": <XIcon />,
                                github: <GithubIcon />,
                                linkedin: <Linkedin />,
                                instagram: <InstagramIcon />,
                            }).map(([platform, icon]) => (
                                <div key={platform} className="flex items-center space-x-2">
                                    {React.cloneElement(icon, {
                                        className: "text-zinc-400",
                                        size: 20,
                                    })}
                                    <input
                                        type="url"
                                        name={`socialLinks.${platform}`}
                                        value={formData.socialLinks[platform] || ""}
                                        onChange={handleInputChange}
                                        placeholder={`${
                                            platform.charAt(0).toUpperCase() + platform.slice(1)
                                        } URL`}
                                        className="w-full bg-zinc-900/50 border border-zinc-700 rounded-lg px-3 py-2 text-zinc-100 focus:outline-none focus:border-amber-500"
                                    />
                                </div>
                            ))}
                        </div>
                    </section>
                </section>
            ),
        },
        notifications: {
            icon: <Bell size={20} />,
            title: "Notifications",
            component: (
                <section className="space-y-4">
                    <section className="bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-6">
                        <h2 className="text-xl font-medium text-zinc-100 mb-4 flex items-center gap-2">
                            <button
                                onClick={() => setActiveSection(null)}
                                className="flex items-center px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                            >
                                <ChevronLeft size={20} />
                            </button>
                            <Bell size={20} />
                            Notification Preferences
                        </h2>
                        <div className="space-y-4">
                            {Object.entries(formData.notificationPreferences).map(
                                ([key, value]) => (
                                    <div key={key} className="flex items-center justify-between">
                                        <label className="text-zinc-100">
                                            {key
                                                .replace(/([A-Z])/g, " $1")
                                                .replace(/^./, (str) => str.toUpperCase())}
                                        </label>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                name={`notificationPreferences.${key}`}
                                                checked={value}
                                                onChange={handleInputChange}
                                                className="sr-only peer"
                                            />
                                            <div
                                                className="w-11 h-6 bg-zinc-700 peer-focus:outline-none rounded-full peer 
                                        peer-checked:after:translate-x-full after:content-[''] after:absolute 
                                        after:top-[2px] after:left-[2px] after:bg-white after:border-zinc-300 
                                        after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                                        peer-checked:bg-amber-500"
                                            ></div>
                                        </label>
                                    </div>
                                )
                            )}
                        </div>
                        {/* ... existing notifications section ... */}
                    </section>
                </section>
            ),
        },
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <SEO title="Settings - BidCoin" description="Settings - BidCoin" />
            <BlockchainOverlay loading={isLoading} />

            {/* Message display */}
            <StatusMessage
                message={message?.content}
                autoDismiss={false}
                mt="mt-0"
                onDismiss={() => {
                    setMessage(null);
                }}
                type={message?.type}
                dismissOnClick={true}
            />

            {/* Section Navigation */}
            {!isMobileView && (
                <div className="flex overflow-x-auto mb-6 bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl p-2 scrollbar-custom">
                    {Object.entries(sections).map(([key, section]) => (
                        <button
                            key={key}
                            onClick={() => setActiveSection(key)}
                            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors whitespace-nowrap
                            ${
                                activeSection === key
                                    ? "bg-amber-500 text-zinc-900"
                                    : "text-zinc-400 hover:text-zinc-100"
                            }`}
                        >
                            {section.icon}
                            {section.title}
                        </button>
                    ))}
                </div>
            )}
            {isMobileView && !activeSection && (
                <div className="flex flex-col space-y-2">
                    {Object.entries(sections).map(([key, section]) => (
                        <button
                            key={key}
                            onClick={() => setActiveSection(key)}
                            className="flex items-center justify-between w-full p-4 bg-zinc-800/30 backdrop-blur-sm border border-zinc-700/50 rounded-xl"
                        >
                            <div className="flex items-center gap-3">
                                {section.icon}
                                <span className="text-zinc-100">{section.title}</span>
                            </div>
                            <ChevronRight className="text-zinc-400" size={20} />
                        </button>
                    ))}
                </div>
            )}
            {/* {isMobileView && activeSection && (
                <button
                    onClick={() => setActiveSection(null)}
                    className="flex items-center gap-2 px-4 py-2 rounded-lg transition-colors whitespace-nowrap text-zinc-400 hover:text-zinc-100"
                >
                    <ChevronLeft size={20} />
                    Back
                </button>
            )} */}
            {/* Section Content */}
            <form onSubmit={handleSubmit} className="space-y-6 pt-4">
                {sections[activeSection]?.component}

                {/* Action Buttons - Always visible */}
                <div className="flex justify-between items-center sticky bottom-4 bg-zinc-900/90 backdrop-blur-sm p-4 rounded-xl border border-zinc-700/50">
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="flex items-center justify-center gap-2 w-36 h-10 bg-amber-500 hover:bg-amber-600 rounded-lg text-zinc-900 font-medium transition-colors disabled:opacity-50"
                    >
                        <Save size={20} />
                        Save Changes
                    </button>
                    <button
                        type="button"
                        onClick={handleLogout}
                        className="flex items-center justify-center gap-2 w-36 h-10 bg-red-500 hover:bg-red-600 rounded-lg text-white font-medium transition-colors"
                    >
                        <LogOut size={20} />
                        Logout
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserSettings;
