import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits } from "ethers";
import contractABI from "../assets/abis/StakingContract.json";
import newAuctionABI from "../assets/abis/AuctionImplV4.json";

import tetherTokenABI from "../assets/abis/NinjaTetherToken.json";
import bidCoinABI from "../assets/abis/BidCoin.json";
// import auctionFactoryAbi from "../assets/abis/v4/AuctionFactory.json";

import { getAppKitProvider, getAppKitAccount } from "../Services/AlchemyProvider";

import { ethers } from "ethers";

const stakingContractAddress = process.env.REACT_APP_STAKINGCONTRACT_ADDRESS;
const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;
// const auctionFactoryAddress = process.env.REACT_APP_AUCTIONFACTORY_ADDRESS;

const alchemyRPCUrl = process.env.REACT_APP_DEV_RPC_URL;
export const web3 = createAlchemyWeb3(alchemyRPCUrl);
const web3Provider = new ethers.JsonRpcProvider(alchemyRPCUrl);

// export const tetherTokenContract = new web3.eth.Contract(tetherTokenABI.abi, tetherTokenAddress);

// export const bidCoinContract = new web3.eth.Contract(bidCoinABI.abi, bidCoinAddress);

// export const stakingContract = new web3.eth.Contract(contractABI.abi, stakingContractAddress);

export const tetherTokenContract = new web3.eth.Contract(
    tetherTokenABI.abi,
    tetherTokenABI.address,
    web3Provider
);

export const bidCoinContract = new web3.eth.Contract(
    bidCoinABI.abi,
    bidCoinABI.address,
    web3Provider
);

export const stakingContract = new web3.eth.Contract(
    contractABI.abi,
    contractABI.address,
    web3Provider
);

// export const auctionFactoryContract = new ethers.Contract(
//     auctionFactoryAddress,
//     auctionFactoryAbi.abi,
//     web3Provider
// );

/**
 *
 */

export const initializeAuctionContract = (contractAddress) => {
    return new web3.eth.Contract(newAuctionABI.abi, contractAddress);
};

/**
 * Gets the connected wallet address using AppKit
 */
const getWalletAddress = async () => {
    try {
        const userAddress = localStorage.getItem("userAddress");

        // console.log("🚀 ~ getWalletAddress ~ account:" + userAddress);

        return account?.address || "0x0";
    } catch (error) {
        console.error("Error getting wallet address:", error);
        return "0x0";
    }
};

/**
 * Modified loadUserData to use AppKit
 */
export const loadUserData = async () => {
    try {
        const userAddress = localStorage.getItem("userAddress");
        if (!userAddress || userAddress === "0x0") {
            return null;
        }
        const [
            usdtBalanceV2,
            bidBalanceV2,
            stakerInfoV2,
            // auctionsArrayV2
        ] = await Promise.all([
            tetherTokenContract.methods.balanceOf(userAddress).call(),
            bidCoinContract.methods.balanceOf(userAddress).call(),
            stakingContract.methods.stakers(userAddress).call(),
            // ["0x0165878A594ca255338adfa4d48449f69242Eb8F"],
            // auctionFactoryContract.getAllAuctions(),
        ]);
        // console.log({
        //     usdtBalanceV2,
        //     bidBalanceV2,
        //     stakerInfoV2,
        //     // auctionsArrayV2,
        // });
        // console.log("passed first check" + userAddress);

        const auctionsArray = ["0x0165878A594ca255338adfa4d48449f69242Eb8F"];
        let auctionsWonCount = 0;
        // console.log("🚀 ~ loadUserData ~ auctionsArray:", auctionsArray);
        const auctionData = await Promise.all(
            auctionsArray.map(async (address) => {
                const contract = new web3.eth.Contract(newAuctionABI.abi, newAuctionABI.address);
                // console.log("🚀 ~ auctionsArray.map ~ auctionsArray:" + auctionsArray);
                const totalProfit = await contract.methods.auctionEnded().call();
                if (totalProfit == true) {
                    const winningBidder = await contract.methods.winningBidder().call();
                    // console.log(
                    //     "🚀 ~ auctionsArray.map ~ winningBidder:" +
                    //         winningBidder +
                    //         " + userAddress" +
                    //         userAddress
                    // );
                    if (winningBidder.toLowerCase() === userAddress.toLowerCase()) {
                        auctionsWonCount++;
                        // console.log(
                        //     "🚀 ~ auctionsArray.map ~ auctionsWonCount:" + auctionsWonCount
                        // );
                    }
                }
            })
        );
        // console.log("🚀 ~ loadUserData ~ auctionsArray:", auctionsArray);
        // if (!userAddress) {
        //     return;
        // }
        // // const totalStaked = await stakingContract.methods.totalStaked().call();
        // const stakerInfo = await stakingContract.methods.stakers(userAddress).call();

        // const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
        // const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };

        // Query all auctions in auctionFactory contract function to getAllAuctions, and check state variable in each contract to see if winningBidder address == userAddress
        // console.log("stakerInfoV2", stakerInfoV2);
        const userData = {
            address: stakingContractAddress,

            bidBalance: safeFormatUnits(bidBalanceV2, 18),
            /** stakedAmount uint256, rewardAmount uint256, unstakeRequestTime uint256, unstakeAmount uint256, totalRewardClaimed uint256, bidCounts uint256, totalValuePlaced uint256, burnCounts uint256, tokensBurned uint256, auctionEndedCounts uint256, auctionWins uint256

[ stakers(address) method Response ]
  stakedAmount   uint256 :  130000000000000000
  rewardAmount   uint256 :  399999
  unstakeRequestTime   uint256 :  1735779549
  unstakeAmount   uint256 :  20000000000000000
  totalRewardClaimed   uint256 :  0
  bidCounts   uint256 :  22
  totalValuePlaced   uint256 :  22000000
  burnCounts   uint256 :  14
  tokensBurned   uint256 :  280000000000000000
  auctionEndedCounts   uint256 :  3
  auctionWins   uint256 :  3
 */

            bidCounts: stakerInfoV2.bidCounts,
            burnCounts: stakerInfoV2.burnCounts,
            auctionEndedCounts: stakerInfoV2.auctionEndedCounts,
            tokensBurned: Number(formatUnits(stakerInfoV2.tokensBurned, 18)).toFixed(2),
            auctionWins: stakerInfoV2.auctionWins,
            auctionEnded: stakerInfoV2.auctionEnded,
            auctionWinner: stakerInfoV2.auctionWinner,

            rewards: Number(formatUnits(stakerInfoV2.rewardAmount, 6)).toFixed(2),

            stakedAmount: Number(formatUnits(stakerInfoV2.stakedAmount, 18)).toFixed(2),
            // totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            stakerInfo: stakerInfoV2,
            usdtBalance: safeFormatUnits(usdtBalanceV2, 6),
        };
        var saveData = userData;
        localStorage.setItem("userDataBlockchain", saveData);
        var eventCounts = JSON.parse(localStorage.getItem("eventCounts"));
        if (eventCounts) {
            const {
                BidPlaced,
                BidBurned,
                AuctionCreated,
                AuctionRemoved,
                AuctionInitialized,
                AuctionEnded,
                RefundClaimed,
                AssetDelivered,
                OwnerConfirmedDelivery,
                WinnerConfirmedDelivery,
            } = eventCounts;

            Object.assign(userData, {
                bidCount: BidPlaced + BidBurned,
                auctionCount: AuctionEnded,
                auctionWonCount: auctionsWonCount,
                auctionCreatedCount: AuctionCreated,
                auctionRemovedCount: AuctionRemoved,
                auctionInitializedCount: AuctionInitialized,
                auctionEndedCount: AuctionEnded,
                refundClaimed: RefundClaimed,
                assetDelivered: AssetDelivered,
                ownerConfirmedDelivery: OwnerConfirmedDelivery,
                winnerConfirmedDelivery: WinnerConfirmedDelivery,
            });
        }
        // console.log("🚀 ~ loadUserData ~ userData:", userData);
        return userData;
    } catch (error) {
        console.error("Error loading auction data:", error);
        return null;
    }
};

/**
 * Modified connectWallet to use AppKit
 */
export const connectWallet = async () => {
    try {
        const address = await getWalletAddress();
        if (address && address !== "0x0") {
            return {
                address,
                status: "",
            };
        }
        return {
            address: "",
            status: "No wallet connected",
        };
    } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
    }
};

/**
 * Modified getCurrentWalletConnected to use AppKit
 */
export const getCurrentWalletConnected = async () => {
    try {
        const address = await getWalletAddress();
        if (address && address !== "0x0") {
            return {
                address,
                status: "",
            };
        }
        return {
            address: "",
            status: "🦊 Connect wallet using the top right button.",
        };
    } catch (err) {
        return {
            address: "",
            status: "😥 " + err.message,
        };
    }
};

/**
 * Modified checkAndSwitchChain to use AppKit
 */
export const checkAndSwitchChain = async () => {
    try {
        const provider = await getAppKitProvider();
        if (!provider) return;

        const chainId = await provider.request({ method: "eth_chainId" });

        if (chainId !== "0xa4b1") {
            // 0xa4b1 is Arbitrum One
            await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0xa4b1" }],
            });
            window.location.reload();
        }
    } catch (error) {
        console.error("Error switching chain:", error);
    }
};
