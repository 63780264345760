import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
// import { parseUnits, formatUnits, isAddress } from "ethers";
import contractABI from "../assets/abis/AuctionImplV4.json";
import tetherTokenABI from "../assets/abis/NinjaTetherToken.json";
import bidCoinABI from "../assets/abis/BidCoin.json";
// import auctionFactoryABI from "../assets/abis/AuctionFactory.json";
import treasuryABI from "../assets/abis/Treasury.json";
// import governanceABI from "../assets/abis/v4/GovernanceContract.json";
const tetherTokenAddress = process.env.REACT_APP_CUSDT_ADDRESS;
const bidCoinAddress = process.env.REACT_APP_BIDCOIN_ADDRESS;

const alchemyKey = "wss://arb-mainnet.g.alchemy.com/v2/z3GTWUvrqDHlJnoPCAfcAihbeVNJFkzg";
const web3 = createAlchemyWeb3(alchemyKey);

export const tetherTokenContract = new web3.eth.Contract(tetherTokenABI.abi, tetherTokenAddress);

export const bidCoinContract = new web3.eth.Contract(bidCoinABI.abi, bidCoinAddress);

// Create a function to initialize the auction contract
/**
 *
 */
export const initializeAuctionContract = (contractAddress) => {
    return new web3.eth.Contract(contractABI.abi, contractAddress);
};

// Create a function to initialize the governance contract
/**
 *
 */
// export const initializeGovernanceContract = (contractAddress) => {
    // return new web3.eth.Contract(governanceABI.abi, contractAddress);
// };

// Create a function to initialize the treasury contract
/**
 *
 */
export const initializeTreasuryContract = (contractAddress) => {
    return new web3.eth.Contract(treasuryABI.abi, contractAddress);
};

// Create a function to initialize the auction factory contract
/**
 *
 */
// export const initializeAuctionFactoryContract = (contractAddress) => {
    // return new web3.eth.Contract(auctionFactoryABI.abi, contractAddress);
// };
