import React, {
    createContext,
    useContext,
    useReducer,
    useCallback,
    useEffect,
    useState,
    useRef,
} from "react";
import { toast } from "react-toastify";
import { useAuth } from "./AuthContext";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";

const NotificationContext = createContext();

const notificationReducer = (state, action) => {
    switch (action.type) {
        case "ADD_NOTIFICATION":
            return [...state, action.payload];
        case "REMOVE_NOTIFICATION":
            return state.filter((notification) => notification.id !== action.payload);
        case "CLEAR_NOTIFICATIONS":
            return [];
        default:
            return state;
    }
};

export const NotificationProvider = ({ children }) => {
    const [notifications, dispatch] = useReducer(notificationReducer, []);
    const { user } = useAuth();
    const [hubConnection, setHubConnection] = useState(null);
    const showNotificationRef = useRef(null);
    const connectionRef = useRef(null);

    const showNotification = useCallback(
        ({ type, title, message, category }) => {
            // Check user preferences before showing notification
            if (user?.notificationPreferences) {
                const {
                    emailNotifications,
                    pushNotifications,
                    bidNotifications,
                    auctionNotifications,
                    stakingNotifications,
                } = user.notificationPreferences;

                // Check if this category of notification is enabled
                const shouldShow = {
                    bid: bidNotifications,
                    auction: auctionNotifications,
                    staking: stakingNotifications,
                    system: true,
                    broadcast: true,
                }[category];

                if (!shouldShow) return;

                // Show toast notification with proper type handling
                const showToast = () => {
                    switch (type) {
                        case "success":
                            toast.success(message);
                            break;
                        case "error":
                            toast.error(message);
                            break;
                        case "warning":
                            toast.warning(message);
                            break;
                        case "info":
                            toast.info(message);
                            break;
                        default:
                            toast(message);
                    }
                };

                showToast();

                // Add to notifications list
                const newNotification = {
                    id: Date.now(),
                    type,
                    title,
                    message,
                    category,
                    timestamp: new Date(),
                    read: false,
                };

                dispatch({ type: "ADD_NOTIFICATION", payload: newNotification });

                if (
                    emailNotifications &&
                    ["bid_won", "auction_ended", "staking_reward"].includes(category)
                ) {
                    // console.log("Send email notification:", newNotification);
                }

                if (pushNotifications) {
                    // console.log("Send push notification:", newNotification);
                }
            }
        },
        [user?.notificationPreferences]
    );

    showNotificationRef.current = showNotification;

    useEffect(() => {
        let mounted = true;

        const createHubConnection = async () => {
            try {
                if (connectionRef.current) {
                    return;
                }

                const connection = new HubConnectionBuilder()
                    .withUrl(`${process.env.REACT_APP_API_BASE_URL_WS}/notificationHub`, {
                        skipNegotiation: true,
                        transport: signalR.HttpTransportType.WebSockets,
                        withCredentials: true,
                    })
                    .withAutomaticReconnect([0, 2000, 5000, 10000, null])
                    // .configureLogging(LogLevel.Information)
                    .build();

                connection.onclose((error) => {
                    // console.log("SignalR Connection closed:", error);
                    connectionRef.current = null;
                });

                connection.onreconnecting((error) => {
                    // console.log("SignalR Reconnecting:", error);
                });

                connection.onreconnected((connectionId) => {
                    // console.log("SignalR Reconnected:", connectionId);
                });

                connection.on("ReceiveBroadcast", (title, message, type) => {
                    showNotificationRef.current?.({
                        type: type.toLowerCase(),
                        title,
                        message,
                        category: "broadcast",
                    });
                });

                if (user?.id) {
                    connection.on("ReceiveNotification", (title, message, type) => {
                        showNotificationRef.current?.({
                            type: type.toLowerCase(),
                            title,
                            message,
                            category: "user",
                        });
                    });
                }

                await connection.start();
                // console.log("SignalR Connected");

                if (mounted) {
                    connectionRef.current = connection;
                    setHubConnection(connection);
                }
            } catch (err) {
                console.error("Error establishing SignalR connection:", err);
                connectionRef.current = null;

                setTimeout(createHubConnection, 5000);
            }
        };

        createHubConnection();

        return () => {
            mounted = false;
            if (connectionRef.current) {
                connectionRef.current.stop();
                connectionRef.current = null;
            }
        };
    }, [user?.id]);

    const markAsRead = useCallback((notificationId) => {
        dispatch({ type: "REMOVE_NOTIFICATION", payload: notificationId });
    }, []);

    const clearAll = useCallback(() => {
        dispatch({ type: "CLEAR_NOTIFICATIONS" });
    }, []);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                showNotification,
                markAsRead,
                clearAll,
                hubConnection,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error("useNotifications must be used within a NotificationProvider");
    }
    return context;
};
