import React, { Suspense, useEffect, lazy, useState } from "react";
import { Route, Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Confetti from "react-confetti";
import { initAllContracts, loadAuctions } from "../Services/BlockChainService";
import useSound from "../Services/SoundService";
import { initializeAuctionContract } from "../Services/AlchemyAuctionInteract";
import AppFooter from "./Common/AppFooter";
import AppHeader from "./Common/AppHeader";
import ErrorBoundary from "./ErrorBoundary";
import { useLoading } from "./LoadingContext";
import HowItWorksScreen from "./HowItWorks";
import CookieConsentBanner from "./Cookies/CookieConsentBanner";
import { BlockchainOverlay } from "./Common/LoadingScreen";
import AchievementListenerWrapper from "./Achievements/AchievementListenerWrapper";
import { infoMessageService } from "../Services/InfoMessageService";
import UserProfileRewards from "./Profile/UserProfile";
import CookiePolicy from "../helpers/cookie-policy";
import PrivacyCompliance from "../helpers/privacy";
import truncateEthAddress from "truncate-eth-address";
import { useAppKitAccount } from "@reown/appkit/react";
import Cookies from "js-cookie";
import { useAuth } from "../Services/AuthContext";
import { useNotifications } from "../Services/NotificationContext";
import { initializeStakingContractWS } from "../Services/AlchemyStakingInteract";
import { formatUnits } from "ethers";
import Breadcrumb from "./Common/Breadcrumb";
// Lazy load all components
const HomePage = lazy(() => import("./HomePage"));
const AuctionsUI = lazy(() => import("../Feature/Auction/AuctionsUI"));
const AuctionDetails = lazy(() => import("../Feature/Auction/AuctionDetailsUI"));
// const CreateAuction = lazy(() => import("./CreateAuction"));
// const GovernanceVoting = lazy(() => import("./GovernanceUI"));
// const UserProfileRewards = lazy(() => import("./Profile/UserProfile"));
// const NotificationCenter = lazy(() => import("./NotificationCenter"));
const StakingInterface = lazy(() => import("./StakingUI"));
const PresaleUI = lazy(() => import("./PresaleUI"));
const ReferralCommission = lazy(() => import("./ReferralUI"));
const TermsAndConditionsPage = lazy(() => import("./TermsAndCondtions"));
const LegalRefundPage = lazy(() => import("./LegalRefund"));
const RefundPage = lazy(() => import("./Refund"));
const AirdropSubscription = lazy(() => import("./AirDrop/AirDropUI"));
const ArticlesList = lazy(() => import("./News/ArticleList"));
const ArticleDetail = lazy(() => import("./News/ArticleDetail"));
const Error404 = lazy(() => import("./Error404"));
const QuizUI = lazy(() => import("./Quiz/QuizUI"));
const ZealyLeaderboard = lazy(() => import("./Community/ZealyLeaderboard"));
const CommunityHub = lazy(() => import("./Community/CommunityHub"));

const QuizList = lazy(() => import("./Quiz/QuizList"));
// const QuizResult = lazy(() => import("./Quiz/QuizResult"));
/**
 *
 */
const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        /**
         *
         */
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

/**
 *
 */
const CustomAppRouter = () => {
    const { account, isConnected } = useAppKitAccount();
    const { isLoading, setIsLoading } = useLoading();
    const [consent, setConsent] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize(); // Add this hook to get window dimensions
    const playBidSound = useSound("/sounds/place_bid_sound.mp3");
    // useSound("/sounds/place_bid_sound.mp3");
    const playAuctionEnd = useSound("/sounds/place_bid_sound.mp3");
    const [messages, setMessages] = useState([]);
    const location = useLocation(); // Add this hook to get current route
    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const { showNotification } = useNotifications();

    // Add new state for referral tracking
    const [referralCode, setReferralCode] = useState(null);

    /**
     *
     */
    const handleAcceptCookies = () => {
        setConsent(true);
    };

    useEffect(() => {
        /**
         *
         */
        const initContracts = async () => {
            setIsLoading(true);
            await initAllContracts();
            // subscribeToAllBidEvents();
            setIsLoading(false);
        };
        initContracts();
    }, [setIsLoading]);
    useEffect(() => {
        const initMessages = async () => {
            await infoMessageService.getFromLocalStorage();
            const routeMessages = infoMessageService.getMessagesForRoute(location.pathname);
            setMessages(routeMessages);
        };

        initMessages();
    }, [location.pathname]);

    // Update referral code handler
    useEffect(() => {
        const handleReferralCode = () => {
            const pathParts = location.pathname.split("/");
            if (pathParts[1] === "ref" && pathParts[2]) {
                const code = pathParts[2];
                // Store referral code in localStorage for persistence
                Cookies.set("referralCode", code);
                localStorage.setItem("referralCode", code);
                setReferralCode(code);

                // Instead of navigating directly, trigger onboarding with referral
                localStorage.removeItem("onboardingComplete");
                navigate("/", { state: { referralCode: code } });
            }
        };

        handleReferralCode();
    }, [location, navigate]);

    const handleDismiss = (message) => {
        const messageKey = `${message.id}-${message.updatedAt}`;
        infoMessageService.dismissMessage(messageKey);
        setMessages(messages.filter((msg) => msg.id !== message.id));
    };
    // Move subscription logic to a separate useEffect
    useEffect(() => {
        let subscriptions = [];

        const initializeSubscriptions = async () => {
            try {
                const auctionArray = await loadAuctions();
                const stakingContractAddress = "0x0Ea52e760Bf9B02C547048Cd8e58b0010c96d002";
                const stakingContract = initializeStakingContractWS(stakingContractAddress);
                const userAddress = localStorage.getItem("userAddress");

                if (auctionArray) {
                    subscriptions = [
                        stakingContract.events.Staked({}).on("data", (data) => {
                            // Show notification if staked amount is greater than 50 BID
                            if (formatUnits(data?.returnValues[1], 18) > 50) {
                                showNotification({
                                    type: "success",
                                    title: "Staking Successful",
                                    message: `Successfully staked ${formatUnits(
                                        data?.returnValues[1],
                                        18
                                    )} BID`,
                                    category: "staking",
                                });
                            }
                            // Show notification for user
                            if (data?.returnValues[0] === userAddress) {
                                showNotification({
                                    type: "success",
                                    title: "Staking Successful",
                                    message: `Successfully staked ${formatUnits(
                                        data?.returnValues[1],
                                        18
                                    )} BID`,
                                    category: "staking",
                                });
                            }
                        }),
                        stakingContract.events.UnstakeRequested({}).on("data", (data) => {
                            // Show notification if staked amount is greater than 50 BID
                            if (data?.returnValues[0] === userAddress) {
                                showNotification({
                                    type: "success",
                                    title: "Unstake Request Successful",
                                    message: `Successfully requested unstake`,
                                    category: "staking",
                                });
                            }
                        }),
                        stakingContract.events.RewardClaimed({}).on("data", (data) => {
                            if (data?.returnValues[0] === userAddress) {
                                showNotification({
                                    type: "success",
                                    title: "Reward Claimed",
                                    message: `Successfully claimed ${formatUnits(
                                        data?.returnValues[1],
                                        6
                                    )} USDT`,
                                    category: "staking",
                                });
                            }
                        }),
                        stakingContract.events.RewardDistributed({}).on("data", (data) => {
                            if (data?.returnValues[0] === userAddress) {
                                showNotification({
                                    type: "success",
                                    title: "Reward Distributed",
                                    message: `Successfully distributed ${formatUnits(
                                        data?.returnValues[1],
                                        6
                                    )} USDT`,
                                    category: "staking",
                                });
                            }
                        }),
                    ];
                    subscriptions = auctionArray
                        .map((auctionAddress) => {
                            const contract = initializeAuctionContract(auctionAddress);

                            // Store subscription references for cleanup
                            return [
                                contract.events
                                    .BidPlaced({})
                                    .on("data", (data) => {
                                        showNotification({
                                            type: "success",
                                            title: "Bid Placed",
                                            message: `Bid by ${truncateEthAddress(
                                                data?.returnValues?.bidder.toLowerCase()
                                            )} on ${truncateEthAddress(data?.address)}`,
                                            category: "bid",
                                        });
                                        playBidSound();
                                    })
                                    .on("error", (error) => {
                                        console.error("BidPlaced event error:", error);
                                        setStatus("😥 " + error.message);
                                    }),

                                contract.events
                                    .BidBurned({})
                                    .on("data", (data) => {
                                        showNotification({
                                            type: "success",
                                            title: "Bid Burned",
                                            message: `Bid Burned on ${truncateEthAddress(
                                                data?.address
                                            )} by ${truncateEthAddress(
                                                data?.returnValues?.bidder.toLowerCase()
                                            )}`,
                                            category: "bid",
                                        });
                                        playBidSound();
                                    })
                                    .on("error", (error) => {
                                        console.error("BidBurned event error:", error);
                                        setStatus("😥 " + error.message);
                                    }),

                                contract.events
                                    .AuctionEnded({})
                                    .on("data", (data) => {
                                        showNotification({
                                            type: "success",
                                            title: "Auction Ended",
                                            message: `Auction ${truncateEthAddress(
                                                data?.address?.toLowerCase()
                                            )} ended by ${truncateEthAddress(
                                                data?.returnValues?.winner?.toLowerCase()
                                            )}`,
                                            category: "bid",
                                        });
                                        playAuctionEnd();
                                    })
                                    .on("error", (error) => {
                                        console.error("AuctionEnded event error:", error);
                                        setStatus("😥 " + error.message);
                                    }),
                            ];
                        })
                        .flat();
                }
            } catch (error) {
                console.error("Failed to initialize event subscriptions:", error);
            }
        };

        initializeSubscriptions();

        // Cleanup function to unsubscribe from all events
        return () => {
            subscriptions.forEach((subscription) => {
                if (subscription && subscription.unsubscribe) {
                    subscription.unsubscribe();
                }
            });
        };
    }, [showNotification, playBidSound, playAuctionEnd]); // Dependencies ensure subscriptions are renewed if these change

    // if (isLoading) {
    //   return <LoadingScreen />;
    // }
    return (
        // min-h-screen bg-center subpixel-antialiased bg-gray-900 text-white mt-4
        <div className="">
            <AchievementListenerWrapper>
                {showConfetti && <Confetti width={width} height={height} gravity={0.618} />}
                <AppHeader />

                {/* Add Messages Display */}
                <div className="messages-container">
                    {messages.map((msg) => (
                        <div key={msg.id} className={`message-alert type-${msg.type}`}>
                            {msg.message}
                            {msg.hasImage && (
                                <img src={msg.imageUrl} alt="" className="message-image" />
                            )}
                            {msg.dismissible && (
                                <button onClick={() => handleDismiss(msg)} className="dismiss-btn">
                                    ×
                                </button>
                            )}
                        </div>
                    ))}
                </div>
                <ErrorBoundary>
                    <Suspense fallback={<BlockchainOverlay loading={isLoading} />}>
                        <Routes>
                            {/* Add explicit referral route */}
                            <Route
                                path="/ref/:referralCode"
                                element={
                                    <Navigate
                                        to="/"
                                        replace
                                        state={{ referralCode: location.pathname.split("/")[2] }}
                                    />
                                }
                            />

                            {/* Modify HomePage route to handle referral state */}
                            <Route
                                path="/"
                                element={
                                    <HomePage
                                        referralCode={referralCode}
                                        onRegisterWithReferral={(code) => {
                                            // Handle registration with referral
                                            // console.log("Registering with referral:", code);
                                            // Add your registration logic here
                                        }}
                                    />
                                }
                            />

                            <Route path="/airdrop" element={<AirdropSubscription />} />
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/auctions" element={<AuctionsUI />} />
                            <Route path="/cw/news" element={<ArticlesList />} />
                            <Route path="/cw/news/:id" element={<ArticleDetail />} />
                            <Route path="/cw/leaderboard" element={<ZealyLeaderboard />} />
                            {/* <Route path="/AuctionDetails" element={<AuctionDetails />} /> */}
                            <Route path="/auction-details/:address" element={<AuctionDetails />} />
                            {/* <Route path="/CreateAuction" element={<CreateAuction />} /> */}
                            {/* <Route path="/GovernanceVoting" element={<GovernanceVoting />} /> */}
                            <Route
                                path="/how-bidcoin-auctions-work"
                                element={<HowItWorksScreen />}
                            />
                            {/* <Route path="/profile" element={<UserProfileRewards />} /> */}
                            <Route
                                path="/profile/*"
                                element={
                                    <ProtectedRoute>
                                        <Routes>
                                            <Route
                                                path="settings"
                                                element={<UserProfileRewards />}
                                            />
                                            <Route
                                                path="achievements"
                                                element={<UserProfileRewards />}
                                            />
                                            <Route path="/" element={<UserProfileRewards />} />
                                        </Routes>
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path="/notifications" element={<NotificationCenter />} /> */}
                            <Route path="/staking" element={<StakingInterface />} />
                            <Route
                                path="/term-and-conditions"
                                element={<TermsAndConditionsPage />}
                            />
                            <Route path="/legal-refund" element={<LegalRefundPage />} />
                            <Route path="/cw" element={<CommunityHub />} />
                            <Route path="/cw/quizzes/:id" element={<QuizUI />} />
                            <Route path="/cw/quizzes" element={<QuizList />} />
                            <Route path="/refund" element={<RefundPage />} />
                            <Route path="/cookie-policy" element={<CookiePolicy />} />
                            <Route path="/privacy" element={<PrivacyCompliance />} />
                            <Route path="/presale" element={<PresaleUI />} />
                            {/* <Route path="/referral" element={<ReferralCommission />} /> */}
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                        <CookieConsentBanner onAccept={handleAcceptCookies} />
                    </Suspense>
                </ErrorBoundary>
                <AppFooter />
            </AchievementListenerWrapper>
        </div>
    );
};
const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { showNotification } = useNotifications();

    useEffect(() => {
        if (!user) {
            // Redirect to home page with the return url
            navigate("/", {
                state: {
                    from: location.pathname,
                    message: "Please connect your wallet to access this page",
                },
            });
            showNotification({
                type: "error",
                title: "Error",
                message: `Please connect your wallet to access this page`,
                category: "error",
            });
        }
    }, [user, navigate, location]);

    return user ? children : null;
};
export default CustomAppRouter;
