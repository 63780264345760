import { createAlchemyWeb3 } from "@alch/alchemy-web3";
// import { AlchemyProvider } from "ethers";
import { parseUnits, formatUnits } from "ethers";
import contractABI from "../assets/abis/StakingContract.json";
import tetherTokenABI from "../assets/abis/NinjaTetherToken.json";
import bidCoinABI from "../assets/abis/BidCoin.json";
import treasuryABI from "../assets/abis/Treasury.json";
import { getAppKitAccount } from "./AlchemyProvider";
import { ethers } from "ethers";

const alchemyKey = process.env.REACT_APP_DEV_RPC_URL;
const alchemyKeyWS = process.env.REACT_APP_DEV_RPC_WS_URL;
const web3 = createAlchemyWeb3(alchemyKey);
const web3WS = createAlchemyWeb3(alchemyKeyWS);

export const initializeStakingContractWS = (contractAddress) => {
    return new web3WS.eth.Contract(contractABI.abi, contractABI.address);
};

export const initializeStakingContract = (contractAddress) => {
    return new web3.eth.Contract(contractABI.abi, contractABI.address);
};

export const tetherTokenContract = new web3.eth.Contract(
    tetherTokenABI.abi,
    tetherTokenABI.address
);

export const bidCoinContract = new web3.eth.Contract(bidCoinABI.abi, bidCoinABI.address);
export const stakingContract = new web3.eth.Contract(contractABI.abi, contractABI.address);
export const stakingContractWS = new web3WS.eth.Contract(contractABI.abi, contractABI.address);
export const treasuryContract = new web3.eth.Contract(treasuryABI.abi, treasuryABI.address);
/**
 *
 */
export const claimRewardsHandler = async () => {
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });
        const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";
        const stakerInfo = await stakingContract.methods.stakers(userAddress).call();
        const rewardAmount = stakerInfo.rewardAmount;
        // await stakingContract.claimProfit().send({ from: userAddress });

        // Claim rewards
        const claimTransactionParameters = {
            data: treasuryContract.methods.claimReward(rewardAmount).encodeABI(),
            from: userAddress,
            to: treasuryABI.address,
        };

        const claimTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [claimTransactionParameters],
        });
        // Wait for approval transaction to be mined
        await new Promise((resolve, reject) => {
            /**
             *
             */
            const checkTx = async () => {
                const receipt = await web3.eth.getTransactionReceipt(claimTxHash);
                console.log("receipt", receipt);
                if (receipt) {
                    resolve();
                } else {
                    setTimeout(checkTx, 1000);
                }
            };
            checkTx();
        });
        return true;
    } catch (error) {
        console.error("Error claiming rewards:", error);

        return error;
    }
};

/**
 *
 */
export const loadStakingData = async () => {
    const maxRetries = 3; // Maximum number of retries
    const delay = 5000; // Delay in milliseconds (5 seconds)

    for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
            const userAddress = localStorage.getItem("userAddress");
            if (!userAddress || userAddress === "0x0") {
                return null;
            }

            const totalStaked = await stakingContract.methods.totalStaked().call();
            const stakerInfo = await stakingContract.methods.stakers(userAddress).call();
            // console.log("stakerInfo", stakerInfo);
            const usdtBalance = await tetherTokenContract.methods.balanceOf(userAddress).call();
            const bidBalance = await bidCoinContract.methods.balanceOf(userAddress).call();

            /**
             *
             */
            const safeFormatUnits = (value, decimals) => {
                try {
                    return Number(formatUnits(value || "0", decimals)).toString();
                } catch (error) {
                    console.error("Error formatting units:", error);

                    return "0";
                }
            };

            let timeFromBlockUnstakeTime;
            if (stakerInfo.unstakeRequestTime != "0") {
                // Convert unstakeRequestTime to date (in UTC)
                const unstakeRequestDate = new Date(stakerInfo.unstakeRequestTime * 1000);

                // Calculate the release date (30 days after unstake request)
                const releaseDate = new Date(
                    unstakeRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000
                );

                // Get the current date (in UTC)
                const currentDate = new Date();

                // Calculate the difference in time (milliseconds)
                const timeDifference = releaseDate.getTime() - currentDate.getTime();

                // Convert time difference to days
                const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                if (daysLeft > 0) {
                    timeFromBlockUnstakeTime = `${daysLeft} days left`;
                } else {
                    timeFromBlockUnstakeTime = "Tokens are available for claim";
                }
            } else {
                timeFromBlockUnstakeTime = null;
            }
            /**
             * {
    "address": "0xdc64a140aa3e981100a9beca4e685f962f0cf6c9",
    "bidBalance": "3.32",
    "rewards": "0.00",
    "stakedAmount": "2.00",
    "stakerInfo": {
        "0": "2000000000000000000",
        "1": "0",
        "2": "0",
        "3": "0",
        "4": "0",
        "5": "13",
        "6": "13000000",
        "7": "4",
        "8": "80000000000000000",
        "9": "0",
        "10": "0",
        "stakedAmount": "2000000000000000000",
        "rewardAmount": "0",
        "unstakeRequestTime": "0",
        "unstakeAmount": "0",
        "totalRewardClaimed": "0",
        "bidCounts": "13",
        "totalValuePlaced": "13000000",
        "burnCounts": "4",
        "tokensBurned": "80000000000000000",
        "auctionEndedCounts": "0",
        "auctionWins": "0"
    },
    "totalStaked": "2.00",
    "unstakeRequestTime": null,
    "usdtBalance": "20999995"
}
      "name": "stakers",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "stakedAmount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "rewardAmount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "unstakeRequestTime",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "unstakeAmount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalRewardClaimed",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "bidCounts",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "totalValuePlaced",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "burnCounts",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "tokensBurned",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "auctionEndedCounts",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "auctionWins",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
             */
            const stakingData = {
                address: contractABI.address,
                bidBalance: safeFormatUnits(bidBalance, 18),
                rewards: Number(formatUnits(stakerInfo.rewardAmount, 6)).toFixed(2),
                stakedAmount: Number(formatUnits(stakerInfo.stakedAmount, 18)).toFixed(2),
                stakerInfo,
                totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
                unstakeRequestTime: timeFromBlockUnstakeTime,
                usdtBalance: safeFormatUnits(usdtBalance, 6),
            };
            // console.log("stakingData", stakingData);
            return stakingData;
        } catch (error) {
            console.error("Error loading staking data:", error);
            if (attempt < maxRetries - 1) {
                await new Promise((resolve) => setTimeout(resolve, delay)); // Wait for 5 seconds before retrying
            } else {
                return null; // Return null after max retries
            }
        }
    }
};

/**
 *
 */
export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                address: addressArray[0],
                status: "",
            };

            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Input Stake / Unstake Amount.",
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect to Metamask using the top right button.",
                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        {/* 🦊{" "} */}
                        <a target="_blank" href={"https://metamask.io/download"}>
                            {/* You must install Metamask, a virtual Ethereum wallet, in your browser. */}
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

/**
 *
 */
export const stakeTokensHandler = async (address, stakeInput) => {
    const provider = window.ethereum;
    if (!provider || !address) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        const bidCoinAmount = parseUnits(stakeInput.toString(), 18);

        // Check Allowance
        const allowance = await bidCoinContract.methods
            .allowance(address, contractABI.address)
            .call();
        if (allowance < bidCoinAmount) {
            // Approve spending of BidCoin
            const approveTransactionParameters = {
                data: bidCoinContract.methods
                    .approve(contractABI.address, bidCoinAmount)
                    .encodeABI(),
                from: address,
                to: bidCoinABI.address,
            };

            const approveTxHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [approveTransactionParameters],
            });

            // Wait for approval transaction to be mined
            await new Promise((resolve) => {
                /**
                 *
                 */
                const checkTx = async () => {
                    const receipt = await web3.eth.getTransactionReceipt(approveTxHash);
                    if (receipt) {
                        resolve();
                    } else {
                        setTimeout(checkTx, 1000);
                    }
                };
                checkTx();
            });

            // return { status: "✅ Approval successful. Proceeding with staking..." };
        }

        // Stake tokens
        const stakeTransactionParameters = {
            data: stakingContract.methods.stake(address, bidCoinAmount).encodeABI(),
            from: address,
            to: contractABI.address,
        };

        const stakeTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [stakeTransactionParameters],
        });

        // Wait for staking transaction to be mined
        await new Promise((resolve) => {
            /**
             *
             */
            const checkTx = async () => {
                const receipt = await web3.eth.getTransactionReceipt(stakeTxHash);
                if (receipt) {
                    resolve();
                } else {
                    setTimeout(checkTx, 1000);
                }
            };
            checkTx();
        });

        // Fetch updated staking data
        const totalStaked = await stakingContract.methods.totalStaked().call();
        const stakerInfo = await stakingContract.methods.stakers(address).call();
        const usdtBalance = await tetherTokenContract.methods.balanceOf(address).call();
        const bidBalance = await bidCoinContract.methods.balanceOf(address).call();

        /**
         *
         */
        const safeFormatUnits = (value, decimals) => {
            try {
                return Number(formatUnits(value || "0", decimals)).toString();
            } catch (error) {
                console.error("Error formatting units:", error);

                return "0";
            }
        };
        let timeFromBlockUnstakeTime;
        if (stakerInfo[2] != "0") {
            // Convert unstakeRequestTime to date (in UTC)
            const unstakeRequestDate = new Date(stakerInfo[2] * 1000);

            // Calculate the release date (30 days after unstake request)
            const releaseDate = new Date(unstakeRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000);

            // Get the current date (in UTC)
            const currentDate = new Date();

            // Calculate the difference in time (milliseconds)
            const timeDifference = releaseDate.getTime() - currentDate.getTime();

            // Convert time difference to days
            const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            if (daysLeft > 0) {
                timeFromBlockUnstakeTime = `${daysLeft} days left`;
            } else {
                timeFromBlockUnstakeTime = "Tokens are available for claim";
            }
        } else {
            timeFromBlockUnstakeTime = "No unstake request";
        }
        const stakingData = {
            address: contractABI.address,
            bidBalance: safeFormatUnits(bidBalance, 18),
            rewards: Number(formatUnits(stakerInfo[1], 6)).toFixed(2),
            stakedAmount: Number(formatUnits(stakerInfo[0], 18)).toFixed(2),
            stakerInfo,
            totalStaked: Number(formatUnits(totalStaked, 18)).toFixed(2),
            unstakeRequestTime: timeFromBlockUnstakeTime,
            usdtBalance: safeFormatUnits(usdtBalance, 6),
        };

        return stakingData;
    } catch (error) {
        console.error("Staking error:", error);

        return {
            status: "❌ Staking failed: " + error.message,
        };
    }
};

/**
 *
 */
export const unstakeTokensHandler = async (address, unstakeInput) => {
    if (!window.ethereum || address === null) {
        return {
            status: "💡 Connect your wallet provider to continue.",
        };
    }

    try {
        const walletAddress = localStorage.getItem("userAddress");
        const usdtAmountInWei = parseUnits(unstakeInput.toString(), 18);

        // Request unstake
        const unstakeTransactionParameters = {
            data: stakingContract.methods.requestUnstake(usdtAmountInWei).encodeABI(),
            from: walletAddress,
            to: contractABI.address,
        };

        const unstakeTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [unstakeTransactionParameters],
            from: walletAddress,
        });
        console.log("unstakeTxHash", unstakeTxHash);
        // Get updated staking data
        const stakingData = await loadStakingData();
        return stakingData;
    } catch (error) {
        return {
            status: "❌ " + error.message,
            error: error,
        };
    }
};

// Add new function to withdraw unstaked tokens
export const withdrawUnstakedTokens = async () => {
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });
        const userAddress = addressArray.length > 0 ? addressArray[0] : "0x0";

        const withdrawTransactionParameters = {
            data: stakingContract.methods.withdrawUnstakedTokens().encodeABI(),
            from: userAddress,
            to: contractABI.address,
        };

        const withdrawTxHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [withdrawTransactionParameters],
            from: userAddress,
        });

        return true;
    } catch (error) {
        console.error("Error withdrawing unstaked tokens:", error);
        return error;
    }
};

// Add function to get unstake info
export const getUnstakeInfo = async (address) => {
    try {
        const info = await stakingContract.methods.getUnstakeInfo(address).call();
        return {
            amount: info[0],
            requestTime: info[1],
            canWithdraw: info[2],
            timeRemaining: info[3],
        };
    } catch (error) {
        console.error("Error getting unstake info:", error);
        return null;
    }
};

const getWalletAddress = async () => {
    const account = await getAppKitAccount();
    return account?.address || "0x0";
};
