import React from "react";

// Add this utility function at the top of the file
const isBot = () => {
    // Check if running on server side (SSR)
    if (typeof window === "undefined") return true;
    // Check for common bot signatures
    const botPatterns = [
        "bot",
        "crawler",
        "spider",
        "ping",
        "slurp",
        "facebook",
        "bot",
        "crawler",
        "spider",
        "lighthouse",
        "slurp",
        "googlebot",
        "baiduspider",
        "bingbot",
        "duckduckbot",
        "facebookexternalhit",
        "lighthouse",
        "pagespeed",
        "yandex",
    ];
    const suspiciousSignals = [
        !navigator.webdriver === undefined,
        !window.chrome,
        !navigator.languages,
        navigator.languages?.length === 0,
        !navigator.cookieEnabled,
        // Check for common bot behaviors
        document.documentElement.clientWidth === 0,
        document.documentElement.clientHeight === 0,
        window.innerWidth === 0,
        window.innerHeight === 0,
        // Check for headless browser tells
        !window.sessionStorage,
        !window.localStorage,
        !window.indexedDB,
        !window.devicePixelRatio,
    ].filter(Boolean).length;

    const userAgent = navigator.userAgent.toLowerCase();
    return (
        botPatterns.some((pattern) => userAgent.includes(pattern)) ||
        navigator.userAgent.toLowerCase().includes("bot") ||
        suspiciousSignals > 3 ||
        navigator.webdriver
    );
};

export default isBot;
