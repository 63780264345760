import React from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

const SEO = ({ title, description, keywords, ogImage }) => {
    const defaultTitle = "BidCoin - Decentralized Crypto Auctions & Bidding Platform";
    const defaultDescription =
        "Join BidCoin, the decentralized platform for crypto auctions and bidding. Buy valuable crypto assets at fair prices, stake rewards, and get started with transparent Web3 auctions today!";
    const defaultKeywords =
        "BidCoin, Decentralized Auctions, Crypto Bidding, Web3, Stake Rewards, Crypto Assets, Bidding Wars, Fair Prices, Transparent Auctions, Decentralized Platform, Crypto Trading, Digital Assets, Blockchain Auctions";
    const defaultOgImage = "https://api.bidcoin.live/og/default.jpg";

    // Enhanced Schema Markup with consistent categories
    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "BidCoin",
        description: defaultDescription,
        url: "https://bidcoin.live",
        logo: "https://bidcoin.live/favicon.ico",
        sameAs: ["https://twitter.com/BidCoinNews"],
        applicationCategory: "Decentralized Penny Auctions",
        offers: {
            "@type": "Offer",
            category: "Decentralized Penny Auctions",
        },
    };

    // WebApplication schema with consistent categories
    const webAppSchema = {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        name: "BidCoin",
        description: defaultDescription,
        applicationCategory: "Decentralized Penny Auctions",
        operatingSystem: "Web Browser",
        offers: {
            "@type": "Offer",
            description: "Decentralized crypto auctions and bidding platform",
            category: "Decentralized Penny Auctions",
        },
        featureList: [
            "Decentralized Auctions",
            "Crypto Bidding",
            "Stake Rewards",
            "Transparent Web3 Platform",
        ],
    };

    return (
        <Helmet>
            {/* Basic Meta Tags */}
            <title>{title || defaultTitle}</title>
            <meta name="description" content={description || defaultDescription} />
            <meta name="keywords" content={keywords || defaultKeywords} />

            {/* Canonical Link */}
            <link rel="canonical" href={window.location.href} />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@BidCoinNews" />
            <meta name="twitter:creator" content="@BidCoinNews" />
            <meta name="twitter:title" content={title || defaultTitle} />
            <meta name="twitter:description" content={description || defaultDescription} />
            <meta name="twitter:image" content={ogImage || defaultOgImage} />

            {/* Open Graph Meta Tags */}
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="BidCoin" />
            <meta property="og:title" content={title || defaultTitle} />
            <meta property="og:description" content={description || defaultDescription} />
            <meta property="og:image" content={ogImage || defaultOgImage} />
            <meta property="og:url" content={window.location.href} />

            {/* Additional Meta Tags for SEO */}
            <meta name="application-name" content="BidCoin" />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="BidCoin" />

            {/* Schema.org JSON-LD */}
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            <script type="application/ld+json">{JSON.stringify(webAppSchema)}</script>
        </Helmet>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    ogImage: PropTypes.string,
};

export default SEO;
