export function formatNumberWithCommas(value) {
    if (value == null) {
        return "0";
    }

    // Convert to string if it's not already
    const stringValue = typeof value === "string" ? value : value.toString();
    // Keep only 2 decimal places
    const trimmedValue = stringValue.match(/^\d*\.?\d{0,2}/)[0];

    // Split the string into integer and decimal parts
    const [integerPart, decimalPart] = trimmedValue.split(".");

    // Add commas to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine integer and decimal parts (if exists)
    // toFixed(2)
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

export function formatNumberWithCommasAndDecimals(value, decimals) {
    const formattedValue = formatNumberWithCommas(value);
    return `${formattedValue}.${"00".slice(0, decimals)}`;
}
export const formatNumber = (value, decimals = 2) => {
    if (!value) return "0";

    // Convert to number if string
    const num = typeof value === "string" ? parseFloat(value) : value;

    // Handle large numbers
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(decimals)}M`;
    }
    if (num >= 1000) {
        return `${(num / 1000).toFixed(decimals)}K`;
    }

    return num.toFixed(decimals);
};
